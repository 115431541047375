import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IStrapiEvent } from 'interfaces/events';
import { IMediaImage, IMediaVideo } from 'interfaces/media';

import {
  Wrapper, Title, Event, EventTitle, Space, GatsbyPicture, Picture,
} from './EventsBlock.styled';

interface IQueryEventsBlock {
  allStrapiEvents: {
    nodes: IStrapiEvent<IMediaImage, IMediaImage | IMediaVideo>[];
  }
}

const query = graphql`
  query {
    allStrapiEvents(limit: 4, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        preview {
          ...MediaImageFragment
        }
        video {
          ...MediaVideoFragment
        }
      }
    }
  }
`;

const EventsBlock: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryEventsBlock) => {
      if (!data?.allStrapiEvents?.nodes?.length) return null;
      const events = data.allStrapiEvents?.nodes;

      return (
        <Wrapper>
          <Title>Events</Title>
          <Space />
          {events.map((event) => (
            <Event key={event.id} href={`/events/${event.slug}`} target="_blank">
              {(event.video && !event.preview)
                ? (
                  <Picture
                    src={event.video.localFile.videoScreenshots?.[0]?.publicURL}
                  />
                ) : <GatsbyPicture {...event.preview} />}
              <EventTitle>
                {event.title}
              </EventTitle>
            </Event>
          ))}
        </Wrapper>
      );
    }}
  />
);

export default EventsBlock;

import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

import { Layout } from 'UI';

export const Wrapper = styled(Layout)`
  grid-area: b;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  row-gap: 5.7rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    row-gap: 4rem;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

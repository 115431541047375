import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';

import { Layout, TouchSlider as UITouchSlider, SectionTitle } from 'UI';
import { LeftHalf, RightHalf, Wrapper } from 'UI/TouchSlider/TouchSlider.styled';

export const Container = styled(Layout)`
  overflow: hidden;
  grid-row: 2;
  grid-column: 1 / -1;
  padding: 0;
  row-gap: 0;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 1;
    padding-left: 20px;
    clip-path: inset(0 0 0 20px);
  }
`;

export const Title = styled(SectionTitle)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 0;
  }
`;

export const TouchSlider = styled(UITouchSlider)`
  grid-column: 1/-1;
  grid-row: 3;

  ${Wrapper} {
    @media (max-width: ${MAX_MOB_WIDTH}px) {
      gap: 10px;
    }
  }

  ${LeftHalf} {
    width: 40%;
    left: -10%;
  }

  ${RightHalf} {
    width: 50%;
    left: 50%;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: auto;
    width: 100vw;
  }
`;

import React, { FC } from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';

import { IPressRelease } from 'interfaces/press';

import {
  Container, Title, LinkWrapper, Item, Header, Space, HeaderWrapper,
} from './PressReleases.styled';

const query = graphql`
  query {
    allStrapiPressReleases(
      sort: {order: DESC, fields: date}
      filter: {article: {article: {ne: null}}, isFuture: {eq: false}}
      limit: 3
    ) {
      nodes {
        title
        header
        slug
        id: strapiId
        media {
          ...MediaImageFragment
        }
        file {
          localFile {
            publicURL
          }
          alternativeText
        }
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`;

interface IPressReleases {
  allStrapiPressReleases: {
    nodes: IPressRelease[];
  }
}

const PressReleases: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IPressReleases) => {
      if (!data?.allStrapiPressReleases?.nodes?.length) return null;

      const { allStrapiPressReleases: { nodes } } = data;

      return (
        <Container withPaddings>
          <Header>
            <HeaderWrapper>
              <Title>
                Press
                <br />
                releases
              </Title>
              <LinkWrapper>
                <Link to="/press-releases/">All press releases</Link>
              </LinkWrapper>
            </HeaderWrapper>
          </Header>
          <Space />
          {nodes.map(({
            title, id, media, slug, date, header,
          }) => (
            <Item
              key={id}
              title={title}
              header={header}
              slug={slug}
              media={media[0]}
              date={date}
            />
          ))}
        </Container>
      );
    }}
  />
);

export default PressReleases;

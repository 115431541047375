import styled from '@emotion/styled';

import { Layout, NextText } from 'UI';
import { MAX_MOB_WIDTH } from 'constants/sizes';

import UIInterviews from './Interviews/Interviews';
import UIArticles from './Articles/Articles';

export const Container = styled(Layout)`
  margin-bottom: 3rem;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 40px;
    margin-bottom: 20px;
  }
`;

export const InterviewsContainer = styled(Layout)`
  margin-top: 5rem;
  padding-bottom: 0;
  padding-right: 0;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 0;
    padding-top: 0;
    row-gap: 40px;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Title = styled.h2`
  grid-row: 1;
  grid-column: 1/-1;
  white-space: pre-wrap;
  margin-top: 3em;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 0;
  }
`;

export const Interviews = styled(UIInterviews)`
  grid-row: 2;
  grid-column: 1/-1;
  max-width: 100%;
`;

export const Articles = styled(UIArticles)`
  grid-column: 1/-1;
`;

export const Header = styled.div`
  grid-row: 1;
  grid-column: 1 / 3;
`;

export const LinkWrapper = styled(NextText)`
  margin-top: 1em;
  display: block;
`;

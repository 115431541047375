import React, { FC } from 'react';

import { Line, Pointer } from './Scrollbar.styled';

interface IScrollbar {
  position?: number;
}

const Scrollbar: FC<IScrollbar> = ({ position = 0 }) => (
  <Line>
    <Pointer
      style={{
        right: `${100 + position}%`,
      }}
    />
  </Line>
);

export default Scrollbar;

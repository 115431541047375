import React, { FC } from 'react';

import { IMediaCoverage } from 'interfaces/press';
import { IMetaPreview } from 'interfaces/page';
import NewsLinks from 'components/blocks/MediaCoverage/NewsList/NewsLinks/NewsLinks';

import {
  Container, HeaderWrapper, Title, Header,
} from './Articles.styled';

interface IArticles {
  items: IMediaCoverage[];
  className?: string;
  defaultImage?: IMetaPreview;
}

const Articles: FC<IArticles> = ({ className, items, defaultImage }) => (
  <Container className={className} gridOnly>
    <HeaderWrapper>
      <Title>
        <Header>Articles</Header>
      </Title>
    </HeaderWrapper>
    <NewsLinks
      articles={items}
      defaultImage={defaultImage}
    />
  </Container>
);

export default Articles;

import styled from '@emotion/styled';

export const Line = styled.div`
  grid-column: 1/-1;
  height: 2px;
  background: var(--border-color);
  position: relative;
`;

export const Pointer = styled.div`
  height: 2px;
  left: 0;
  top: -1em;
  position: absolute;
  padding: 1em 0;
  transition: transform 0.1s linear;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 1em;
    left: 0;
    height: 2px;
    width: 100%;
    background: var(--red);
  }
`;

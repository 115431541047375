import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaFile } from 'interfaces/media';
import { FileLink } from 'UI';

import {
  Container,
  Header,
  Title,
  Description,
  Footnote,
  HR,
  Download,
  PRWrapper,
  PRDescription,
  PREmail,
} from './Welcome.styled';

interface IWelcomeBlock {
  strapiWidgets: IWidget<null, IMediaFile>;
}

const query = graphql`
  query {
    strapiWidgets(title: {eq: "welcome"}, page: {eq: "press_area"}) {
      title
      id: strapiId
      header
      text {
        id
        title
        text
      }
      slide {
        id
        title
        file {
          ...MediaFileFragment
        }
      }
    }
  }
`;

const Welcome: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IWelcomeBlock) => {
      if (!data?.strapiWidgets) return null;
      const { header = '', text = [], slide } = data.strapiWidgets;

      return (
        <Container>
          <Header>{header}</Header>
          <Description>
            {text[0]?.text}
          </Description>
          <Title>
            {text[1]?.text}
          </Title>
          <Footnote>
            {text[2]?.text}
          </Footnote>

          <PRWrapper>
            <PRDescription>{text[3]?.text}</PRDescription>
            <PREmail href="mailto:pr@wayray.com">pr@wayray.com</PREmail>
          </PRWrapper>

          <HR />

          <Download>
            <span>Download text:</span>
            {slide.map(({
              title, file, id,
            }) => (
              <FileLink
                key={id}
                text={title}
                link={file?.localFile?.publicURL}
                title={title}
              />
            ))}
          </Download>
        </Container>
      );
    }}
  />
);

export default Welcome;

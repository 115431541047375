import styled from '@emotion/styled';

import {
  Layout,
  DownloadButton as UIDownloadButton,
  MiddleText,
  TouchSlider,
  SectionTitle as UISectionTitle,
} from 'UI';
import Scrollbar from 'UI/TouchSlider/Scrollbar/Scrollbar';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const Container = styled(Layout)`
  margin: 5.7rem 0;
  align-items: start;

  grid-template-areas:
    'a a b b b b b b b b'
    'a a b b b b b b b b'
    'a a b b b b b b b b';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      'a a b b b b'
      'a a b b b b'
      'a a b b b b';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 20px;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    padding-top: 40px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
  }
`;

export const Header = styled.div`
  grid-area: a;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  row-gap: 1em;
  grid-row: 1 / 4;
  height: 100%;
  align-content: start;
  grid-template-rows: auto 1fr;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 1;
    padding-bottom: 10px;
  }
`;

export const TitleWrapper = styled.div`
  grid-row: 1 / 4;
  height: 100%;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 1;
  }
`;

export const Sticky = styled.div`
  display: block;
  position: sticky;
  padding-bottom: 1em;

  @media (min-width: ${MAX_MOB_WIDTH}px) {
    top: 1em;
    padding-bottom: 0;
    margin-bottom: 4em;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    position: static;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const Title = styled(MiddleText)`
  white-space: pre-line;
  cursor: pointer;

  &.active {
    color: var(--red);
  }
`;

export const DownloadText = styled.span`
  font-weight: 500;
`;

export const DownloadButton = styled(UIDownloadButton)``;

export const Link = styled.a`
  transition: 0.2s ease-in-out;
  grid-area: a;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 0.5em;
  align-self: end;
  align-items: center;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-area: c;
  }

  :hover {
    color: var(--black-opacity);

    ${DownloadButton} {
      background: var(--black-opacity);
      fill: var(--white);
    }
  }
`;

export const Scroll = styled(Scrollbar)`
  grid-area: b;

  @media (min-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;

export const Slider = styled(TouchSlider)`
  display: none;
  grid-area: b;
  grid-row: 2;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: grid;
    grid-column: 1/-1;
  }
`;

export const SectionTitle = styled(UISectionTitle)`
  margin-bottom: 1em;
`;

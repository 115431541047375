import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';

import { Picture } from './TechnologyPreview.styled';

interface ITechnologyPreviewBlock {
  strapiWidgets: IWidget<null, null>;
}

const query = graphql`
  query {
    strapiWidgets(title: {eq: "technology preview"}, page: {eq: "press_area"}) {
      id: strapiId
      media {
        ...MediaImageFragment
      }
    }
  }
`;

const TechnologyPreview: FC = () => (
  <StaticQuery
    query={query}
    render={(data: ITechnologyPreviewBlock) => {
      if (!data?.strapiWidgets) return null;

      const { strapiWidgets } = data;
      const { media } = strapiWidgets;

      return (
        <Picture
          {...media?.[0]}
          title="asds"
        />
      );
    }}
  />
);

export default TechnologyPreview;

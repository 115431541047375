import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IMediaSvg } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';
import { GatsbySvg } from 'UI';

import {
  Container, Item, Wrapper, LogoWrapper,
} from './Awards.styled';

interface IAwards {
  strapiWidgets: IWidget<null, IMediaSvg>;
}

const query = graphql`
  query {
    strapiWidgets(title: {eq: "awards"}, page: {eq: "press_area"}) {
      slide {
        id
        title
        media {
          ...MediaSVGFragment
        }
      }
    }
  }
`;

const Awards: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IAwards) => {
      if (!data?.strapiWidgets) return null;
      const { slide } = data.strapiWidgets;

      return (
        <Container withPaddings>
          <Wrapper>
            {slide.map(({ title, media, id }) => (
              <Item key={id}>
                <LogoWrapper>
                  <GatsbySvg media={media} wrapper="div" />
                </LogoWrapper>
                <p>{title}</p>
              </Item>
            ))}
          </Wrapper>
        </Container>
      );
    }}
  />
);

export default Awards;

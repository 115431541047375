import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/react';

import { IQueryPage } from 'interfaces/page';

import { setActiveBlock, setPreviousBlock } from 'store/actions';

import Head from 'components/Layout/Head/Head';

import Welcome from 'components/blocks/PressAreaPage/Welcome/Welcome';
import { Intersection } from 'UI';
import TechnologyPreview from 'components/blocks/PressAreaPage/TechnologyPreview/TechnologyPreview';
import MediaCoverage from 'components/blocks/PressAreaPage/MediaCoverage/MediaCoverage';
import PressReleases from 'components/blocks/PressAreaPage/PressReleases/PressReleases';
import HistoryHighlights from 'components/blocks/PressAreaPage/HistoryHighlights/HistoryHighlights';
import Medium from 'components/blocks/PressAreaPage/Medium/Medium';
import Awards from 'components/blocks/PressAreaPage/Awards/Awards';
import Guidelines from 'components/blocks/Brand/Guidelines/Guidelines';
import OfficeImages from 'components/blocks/Brand/PressImages/PressImages';
import Instagram from 'components/blocks/PressAreaPage/Instagram/Instagram';
import EventsBlock from 'components/blocks/PressAreaPage/EventsBlock/EventsBlock';
import { globalStyles } from 'components/blocks/PressAreaPage/Styles/PressArea.styled';

const PressAreaPage: FC = () => {
  const dispatch = useDispatch();

  const setBlock = ({ title, visibility }: ISetBlock) => {
    if (visibility) {
      dispatch(setActiveBlock(`#${title}`));
    } else {
      dispatch(setPreviousBlock(`#${title}`));
    }
  };

  return (
    <>
      <Global styles={globalStyles} />
      <Intersection id="press" way={setBlock}>
        <Welcome />
        <TechnologyPreview />
      </Intersection>

      <Intersection id="press_releases" way={setBlock}>
        <PressReleases />
      </Intersection>

      <Intersection id="media_coverage" way={setBlock}>
        <MediaCoverage />
      </Intersection>

      {/*
        <Intersection id="history" way={setBlock}>
          <HistoryHighlights />
        </Intersection>
       */}

      <Intersection id="events" way={setBlock}>
        <EventsBlock />
      </Intersection>

      <Intersection id="guidelines" way={setBlock}>
        <Guidelines />
        <OfficeImages />
      </Intersection>

      <Intersection id="social" way={setBlock}>
        <Medium />
        {/* <Instagram /> */}
        <Awards />
      </Intersection>
    </>
  );
};

const query = graphql`
  query {
    page: strapiPressAreaPage {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

const PressAreaQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryPage) => {
      const siteInfo = data.page.meta;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
          />
          <PressAreaPage />
        </>
      );
    }}
  />
);

export default PressAreaQuery;

import styled from '@emotion/styled';

import { GatsbyPicture } from 'UI';

export const Picture = styled(GatsbyPicture)`
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;

  > img,
  > source {
    position: absolute;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`;

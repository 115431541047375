import styled from '@emotion/styled';

import { Layout, SectionTitle } from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const Header = styled(SectionTitle)`
  margin-bottom: 0.5rem;
`;

export const Container = styled(Layout)`
  padding: 0;
  padding-top: 5em;
  row-gap: 2rem;

  grid-template-areas:
    'a a b b b b b b b b'
    'a a b b b b b b b b'
    'a a c c c c c c c c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 4em;
    row-gap: 4em;
    grid-template-areas:
      'a a b b b b'
      'a a b b b b'
      'a a b b b b'
      'a a c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
    row-gap: 20px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'b b b b'
      'b b b b'
      'b b b b'
      'c c c c';
  }
`;

export const HeaderWrapper = styled.div`
  grid-area: a;
`;

export const Title = styled.div`
  position: sticky;
  top: 1rem;
`;

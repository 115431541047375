import styled from '@emotion/styled';

import { Layout, DownloadButton as UIDownloadButton, GatsbySvg } from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const Container = styled(Layout)`
  margin: 5.7rem 0 8rem;
  align-items: start;
  row-gap: 4em;

  grid-template-areas:
    'a a a . . . b b b b'
    'c c c c c c c c c c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 4em;
    grid-template-areas:
      'a a . b b b'
      'c c c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 30px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
  }
`;

export const Header = styled.div`
  grid-area: a;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  row-gap: 1em;
`;

export const Title = styled.h2`
  white-space: pre-wrap;
`;

export const Description = styled.div`
  grid-area: b;
  white-space: pre-wrap;
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
`;

export const Footnote = styled.p`
  white-space: pre-wrap;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 10px;
  }
`;

export const DownloadButton = styled(UIDownloadButton)`
  position: absolute;
  right: 1.5em;
  bottom: 0;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    right: 0;
    bottom: 1.5em;
  }
`;

export const CommonFiles = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 2em;
  grid-template-columns: max-content;
`;

export const Link = styled.a``;

export const Logotypes = styled.div`
  grid-area: c;
  display: grid;
  grid-auto-flow: column;
  background: var(--white);

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-auto-flow: row;
  }
`;

export const Logo = styled(GatsbySvg)`
  svg {
    width: 100%;
    padding: 10% 20%;
    height: auto;
  }
`;

export const LogoPreview = styled.a`
  background: var(--white);
  position: relative;
  margin: 1.5em 0;

  &:not(:last-of-type) {
    border-right: 1px solid var(--border-color);

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      border-right: none;
      border-bottom: 1px solid var(--border-color);
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0 1.5em;
  }

  :hover {
    ${DownloadButton} {
      background: var(--black-opacity);
      fill: var(--white);
      color: var(--red);
    }
  }
`;

import React, {
  FC, useRef, useState, useEffect,
} from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { MAX_MOB_WIDTH } from 'constants/sizes';
import { IMediaFile, IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import Image from './Image/Image';
import {
  Container,
  Header,
  Title,
  DownloadButton,
  DownloadText,
  Link,
  Slider,
  Scroll,
  TitleWrapper,
  Sticky,
  SectionTitle,
} from './PressImages.styled';

interface IBrandImages {
  team: IWidget<IMediaImage, IMediaFile>;
  interfaces: IWidget<IMediaImage, IMediaFile>;
  offices: IWidget<IMediaImage, IMediaFile>;
}

const query = graphql`
  fragment BrandImageWidget on StrapiWidgets {
    title
    id: strapiId
    header
    media {
      ...ImagePreviewFragment
    }
    slide {
      id
      title
      header
      file {
        ...MediaFileFragment
      }
    }
  }
  query {
    team: strapiWidgets(title: {eq: "teamImages"}, page: {eq: "brand"}) {
      ...BrandImageWidget
    }
    interfaces: strapiWidgets(title: {eq: "interfacesImages"}, page: {eq: "brand"}) {
      ...BrandImageWidget
    }
    offices: strapiWidgets(title: {eq: "officeImages"}, page: {eq: "brand"}) {
      ...BrandImageWidget
    }
  }
`;

const OfficeImages: FC = () => {
  const [isMobile, setIsMobile] = useState<number>(-1);
  const [activeWidget, setActiveWidget] = useState<number>(0);

  const handleResize = () => {
    if (!window) return;

    if (window.innerWidth < MAX_MOB_WIDTH) {
      setIsMobile(1);
    } else {
      setIsMobile(0);
    }
  };

  const handleWidget = (index: number) => {
    setActiveWidget(index);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StaticQuery
      query={query}
      render={(data: IBrandImages) => {
        if (!data?.team || !data?.interfaces || !data?.offices) return null;

        const { team, interfaces, offices } = data;
        const slides: IWidget<IMediaImage, IMediaFile>[] = [team, interfaces, offices];

        return (
          <Container withPaddings>
            <Header>
              <TitleWrapper>
                <Sticky>
                  <SectionTitle>Press Images</SectionTitle>
                  {slides.map(({ header, id }, index) => (
                    <Title key={id} onClick={() => handleWidget(index)} className={index === activeWidget ? 'active' : ''}>
                      {header}
                    </Title>
                  ))}
                </Sticky>
              </TitleWrapper>
            </Header>
            {isMobile !== 1 && (
              <>
                {slides[activeWidget]?.media.map((item) => (
                  <Image
                    key={item.id}
                    {...item}
                  />
                ))}
              </>
            )}
            {isMobile !== 0 && (
              <Slider
                items={slides[activeWidget]?.media.map(({ caption = '' }) => caption)}
                ref={useRef()}
                scrollbar={<Scroll />}
                itemWidth={100}
              >
                {slides[activeWidget]?.media.map((item) => (
                  <Image
                    key={item.id}
                    {...item}
                  />
                ))}
              </Slider>
            )}
            {slides[activeWidget]?.slide[0] && (
              <Link href={slides[activeWidget].slide[0].file?.localFile.publicURL} target="_blank" download>
                <DownloadButton />
                <DownloadText>
                  {slides[activeWidget].slide[0].header}
                </DownloadText>
              </Link>
            )}
          </Container>
        );
      }}
    />
  );
};

export default OfficeImages;

import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';
import { Layout } from 'UI';

export const LogoWrapper = styled.div`
  height: 6em;
  display: flex;
  align-content: end;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-auto-flow: column;
  align-items: start;
  align-content: center;
  justify-content: stretch;
  justify-items: center;
  font-weight: 500;
  gap: 2em;
  margin: 5em 0 3em;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-auto-flow: row;
    gap: 4em;
    margin: 0;
  }
`;

export const Container = styled(Layout)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

export const Item = styled.div`
  display: grid;
  max-width: 18em;
  justify-content: center;
  justify-items: center;
  gap: 1em;
  color: var(--gray);
  text-align: center;
`;

import React, { FC } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import { IMediaCoverage } from 'interfaces/press';
import { IQueryPage } from 'interfaces/page';

import {
  Container, Title, Interviews, Articles, Header, InterviewsContainer, LinkWrapper,
} from './MediaCoverage.styled';

const query = graphql`
  query {
    page: strapiMediaCoveragePage {
      meta {
        ...StrapiMetaFragment
      }
    }
    articles: allStrapiMediaCoverage(
      sort: {fields: date, order: DESC}
      filter: {type: {eq: "article"}}
      limit: 4
    ) {
      nodes {
        link
        title
        id: strapiId
        media {
          ...MediaImageFragment
        }
        date(formatString: "MMM DD, YYYY")
      }
    }
    interviews: allStrapiMediaCoverage(
      sort: {fields: date, order: DESC}
      filter: {type: {eq: "interview"}}
      limit: 2
    ) {
      nodes {
        link
        title
        id: strapiId
        media {
          ...MediaImageFragment
        }
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`;

interface IMediaCoverageQuery extends IQueryPage {
  articles: {
    nodes: IMediaCoverage[];
  };
  interviews: {
    nodes: IMediaCoverage[];
  };
}

const MediaCoverage: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IMediaCoverageQuery) => {
      if (!data?.interviews?.nodes?.length) return null;

      const { interviews, articles, page } = data;

      return (
        <>
          <Container>
            <Header>
              <Title>
                {page.meta.title}
              </Title>
              <LinkWrapper>
                <Link to="/media-coverage" target="_blank">All media coverage</Link>
              </LinkWrapper>
            </Header>
            <Articles items={articles.nodes} defaultImage={page.meta.preview} />
          </Container>
          <InterviewsContainer withPaddings>
            <Interviews items={interviews.nodes} />
          </InterviewsContainer>
        </>
      );
    }}
  />
);

export default MediaCoverage;

import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

import {
  GatsbyPicture, NewsCard,
} from 'UI';
import { gridGaps } from 'UI/Layout/Layout';
import { Date } from 'UI/NewsCard/NewsCard.styled';

export const Card = styled(NewsCard)`
  ${gridGaps}
  grid-column: span 8;
  grid-row: span 1;
  grid-auto-flow: unset;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    't t t t . . p p'
    'd d d d d d p p';

  ${Date} {
    display: flex;
    gap: 0.5rem;
    align-self: end;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 6;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      't t t t p p'
      'd d d d p p';

    ${Date} {
      flex-direction: row-reverse;
      gap: 1rem;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      't t t p'
      'd d d d';
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 66.6%;
  display: block;

  img {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
  }
`;

import React, {
  FC, forwardRef, Ref, useEffect, useState,
} from 'react';

import { YoutubeVideo } from 'UI';

import {
  Container, Title,
} from './VideoItem.styled';

interface IVideoItem {
  link: string;
  title: string;
  date: string;
  ref?: Ref<HTMLDivElement>;
  isActive: boolean;
}

const VideoItem: FC<IVideoItem> = forwardRef(({
  link, title, date, isActive,
}, ref) => {
  const [active, setActive] = useState<boolean>(false);

  const handleOpenVideo = () => {
    setActive(true);
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <Container ref={ref} className={isActive ? 'active' : ''}>
      <YoutubeVideo
        url={link}
        title={title}
        active={active}
        onOpen={handleOpenVideo}
      />
      <Title>
        <a href={link} target="_blank" rel="noreferrer">
          {title}
        </a>
      </Title>
      <span>
        {date}
      </span>
    </Container>
  );
});

export default VideoItem;

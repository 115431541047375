import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  Layout,
  MiddleText,
  Picture as UIPicture,
  GatsbyPicture as UIGatsbyPicture,
} from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';
import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';

export const Wrapper = styled(Layout)`
  margin-top: 5rem;
  margin-bottom: 5rem;
  grid-template-areas:
    't t t t t t t t t t'
    's s e e e e e e e e'
    's s e e e e e e e e';
  row-gap: 5rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 3rem;
    grid-template-areas:
      't t t t t t'
      'e e e e e e';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-areas:
      't t t t'
      'e e e e';
  }
`;

export const Title = styled.h2`
  grid-area: t;
`;

export const Space = styled.div`
  grid-area: s;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const EventTitle = styled(MiddleText)``;

export const Event = styled.a`
  grid-area: e;
  grid-column: span 4;
  grid-row: span 1;
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;

  :hover {
    ${EventTitle} {
      ${ArrowLinkStyles}
    }
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: span 4;
  }
`;

export const PictureStyle = css`
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Picture = styled(UIPicture)`
  ${PictureStyle}
`;

export const GatsbyPicture = styled(UIGatsbyPicture)`
  ${PictureStyle}
`;

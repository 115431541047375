import styled from '@emotion/styled';

import { MiddleText } from 'UI';
import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';

export const Title = styled(MiddleText)`
  color: var(--black);

  a:hover {
    ${ArrowLinkStyles}
  }
`;

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  align-content: start;
  scroll-snap-align: start;
  flex: 1 1 auto;
  background-color: transparent;

  scroll-snap-align: start;
  flex: 1 1 auto;
  min-width: 80%;
`;

import styled from '@emotion/styled';

import { Layout, MiddleText, Picture } from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';
import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';

export const Wrapper = styled(Layout)`
  display: grid;
  justify-items: start;
  justify-content: start;
  grid-template-rows: max-content;
`;

export const Title = styled(MiddleText)`
  grid-column: 1/5;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
    grid-row: 2;
  }
`;

export const Date = styled.small`
  grid-column: 1/5;
  grid-row: 2;
  font-weight: 500;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
    grid-row: 3;
  }
`;

export const ImageWrapper = styled.a`
  grid-column: 5/-1;
  grid-row: 1/4;
  background: var(--gray-2);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
    grid-row: 1;
  }
`;

export const Image = styled(Picture)`
`;

export const Link = styled.a`
  :hover {
    ${ArrowLinkStyles}
  }
`;

import { css } from '@emotion/react';

import {
  MAX_MOB_WIDTH, MIN_4K, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const globalStyles = css`
  h2 {
    font-size: 48px;
    line-height: 50px;
    letter-spacing: -0.02em;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 36px;
      line-height: 34px;
      letter-spacing: -0.01em;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
`;

import styled from '@emotion/styled';

import { GatsbySvg, Layout } from 'UI';
import { MAX_MOB_WIDTH } from 'constants/sizes';

import MediumArticle from './MediumArticle/MediumArticle';

export const Container = styled(Layout)`
  margin: 5.7rem 0;
  align-items: start;
  row-gap: 4em;
  grid-auto-flow: row;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0;
    row-gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const Logo = styled(GatsbySvg)`
  margin-bottom: 0.1em;

  svg {
    width: 0.9em;
    height: auto;
  }
`;

export const Title = styled.h2`
  grid-column: 1/-1;
  grid-row: 1;
  margin-bottom: 2rem;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 0;
  }
`;

export const Article = styled(MediumArticle)`
  grid-column: 1/-1;
  grid-row: auto;
`;

import styled from '@emotion/styled';

import { Layout, MiddleText, ButtonLink } from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K,
} from 'constants/sizes';

export const Container = styled(Layout)`
  margin: 5.7rem 0;

  grid-template-areas:
    'a a a . . . c c c c'
    'b b b . . . d d d d'
    '. . . . . . h h h h'
    't t . . . . e e e e';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      'a a a c c c'
      'b b b d d d'
      '. . . h h h'
      't t . e e e';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 5.7rem 0 20px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      't t . .'
      'c c c c'
      'd d d d'
      'h h h h'
      'e e e e';
  }
`;

export const PRWrapper = styled.div`
  grid-area: t;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 20px;
  }
`;

export const PRDescription = styled.p`
  color: var(--gray);
  white-space: nowrap;
  margin-bottom: 1em;
`;

export const PREmail = styled(ButtonLink)`
  width: auto;
`;

export const Header = styled.h2`
  grid-area: a;
  white-space: pre-wrap;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0;
  }
`;

export const Description = styled.p`
  grid-area: b;
  white-space: pre-wrap;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled(MiddleText)`
  grid-area: c;
  white-space: pre-wrap;
`;

export const Footnote = styled.p`
  grid-area: d;
  white-space: pre-wrap;
`;

export const HR = styled.hr`
  grid-area: h;
  display: block;
  width: 100%;
  background: var(--border-color);
  height: 1px;
  border: none;

  @media (min-width: ${MIN_2K}px) {
    height: 2px;
  }
`;

export const Download = styled.div`
  grid-area: e;
  color: var(--black);
  font-weight: 500;
  display: grid;
  gap: 1em;
  align-items: center;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, minmax(7em, 1fr));
  justify-items: start;

  > span {
    grid-column: 1/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(5em, 1fr));
  }
`;

import React, { FC } from 'react';

import { IMediaImage } from 'interfaces/media';

import {
  Wrapper, Long, Short, Title, Date, Picture,
} from './PressRelease.styled';

interface IPressRelease {
  title: string;
  slug: string;
  media: IMediaImage;
  className?: string;
  date: string;
  header: string;
}

const PressRelease: FC<IPressRelease> = ({
  title, header, slug, media, className, date,
}) => (
  <Wrapper href={`/press-releases/${slug}`} target="_blank" className={className}>
    <Picture {...media} />
    <Title>
      <Long>{header}</Long>
      <Short>{title}</Short>
    </Title>
    <Date>{date}</Date>
  </Wrapper>
);

export default PressRelease;

import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IMediaDownloadFile, IMediaSvg } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import { MiddleText, NextText } from 'UI';
import {
  Container,
  Header,
  Title,
  Description,
  Footnote,
  CommonFiles,
  Link,
  Logotypes,
  LogoPreview,
  Logo,
  DownloadButton,
} from './Guidelines.styled';

interface IGuidelines {
  strapiWidgets: IWidget<IMediaDownloadFile, IMediaSvg>;
}

const query = graphql`
  query {
    strapiWidgets(title: {eq: "guidelines"}, page: {eq: "brand"}) {
      title
      id: strapiId
      header
      text {
        id
        title
        text
      }
      media {
        ...MediaFileFragment
      }
      slide {
        id
        media {
          ...MediaSVGFragment
        }
        file {
          ...MediaFileFragment
        }
      }
    }
  }
`;

const Guidelines: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IGuidelines) => {
      if (!data?.strapiWidgets) return null;
      const {
        header = '', text = [], media, slide,
      } = data.strapiWidgets;

      return (
        <Container withPaddings>
          <Header>
            <Title>{header}</Title>
            <CommonFiles>
              {media.map(({ id, caption, localFile }) => (
                <NextText key={id}>
                  <Link
                    href={localFile?.publicURL || ''}
                    target="_blank"
                  >
                    {caption}
                  </Link>
                </NextText>
              ))}
            </CommonFiles>
          </Header>
          <Description>
            <MiddleText>{text?.[0]?.title}</MiddleText>
            <Footnote>
              {text?.[0]?.text}
            </Footnote>
          </Description>
          <Logotypes>
            {slide.map((item) => (
              <LogoPreview
                key={item.id}
                href={item.file.localFile.publicURL}
                title="download logotype"
                target="_blank"
                download
              >
                <Logo media={item.media} wrapper="div" />
                <DownloadButton />
              </LogoPreview>
            ))}
          </Logotypes>
        </Container>
      );
    }}
  />
);

export default Guidelines;

import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDE_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

import { GatsbyPicture } from 'UI';
import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';

export const Picture = styled(GatsbyPicture)`
  grid-area: a;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0 -20px 10px;
  }
`;

export const Title = styled.p`
  grid-area: b;

  @media (min-width: ${MAX_TABLET_WIDE_WIDTH}px) {
    font-size: 1.4em;
    line-height: 1.2;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    font-size: 18px;
  }
`;

export const Long = styled.span`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;

export const Short = styled.span`
  @media (min-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;

export const Date = styled.span`
  grid-area: c;
`;

export const Wrapper = styled.a`
  display: grid;
  gap: 1.5em;
  grid-auto-columns: 1fr;

  grid-template-areas:
    'a a a a a a'
    'b b b b . c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      'a a a a'
      'b b b c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    gap: 1em;
    grid-template-areas:
      'a'
      'b'
      'c';
  }

  :hover {
    ${Title} {
      ${ArrowLinkStyles}
    }

    cursor: pointer;
  }
`;

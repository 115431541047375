import styled from '@emotion/styled';

import { Layout, NextText } from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';

import PressRelease from './PressRelease/PressRelease';

export const Container = styled(Layout)`
  row-gap: 5.7rem;
  margin-top: 5em;
  grid-template-areas:
    'a a s s s s s s s s'
    'a a c c c c c c c c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 4em;
    margin-top: 0;
    grid-template-areas:
      'a a s s s s'
      'a a c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 40px;
    grid-template-areas:
      'a a a a'
      'c c c c';
  }
`;

export const Header = styled.div`
  width: 100%;
  grid-auto-flow: row;
  grid-area: a;
  row-gap: 1em;
  grid-row: 1 / 5;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: auto;
  }
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 1em;
`;

export const Title = styled.h2``;

export const LinkWrapper = styled(NextText)`
  margin-top: 1em;
  display: block;
`;

export const Item = styled(PressRelease)`
  grid-area: c;
  grid-row-start: 3;
  grid-row: span 1;
  margin-right: -80px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-right: -48px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row-start: auto;
    margin-right: 0;
  }
`;

export const Space = styled.div`
  grid-area: s;
  height: 4em;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;

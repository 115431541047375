import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IMediaSvg } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Container,
  Article,
  Title,
  Logo,
} from './Medium.styled';

interface IMediumArticle {
  id: string;
  title: string;
  link: string;
  isoDate: string;
  content: {
    encoded: string;
    encodedSnippet: string;
  }
}

interface IMedium {
  strapiWidgets: IWidget<IMediaSvg, null>;
  articles: {
    nodes: IMediumArticle[];
  }
}

const query = graphql`
  query {
    strapiWidgets(title: {eq: "mediumArticles"}, page: {eq: "press_area"}) {
      header
      media {
        ...MediaSVGFragment
      }
    }
    articles: allFeedMediumBlog(limit: 3) {
      nodes {
        id
        title
        link
        isoDate(formatString: "MMM DD")
        content {
          encoded
          encodedSnippet
        }
      }
    }
  }
`;

const Medium: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IMedium) => {
      if (!data.strapiWidgets || !data.articles) return null;
      const { nodes } = data.articles;
      const { header = '', media } = data.strapiWidgets;

      return (
        <Container>
          <Title>
            <a href="https://medium.com/@WayRay" target="_blank" rel="noreferrer">
              <Logo media={media[0]} wrapper="div" />
              {header}
            </a>
          </Title>
          {nodes.map(({
            id, title, link, isoDate, content,
          }) => (
            <Article
              key={id}
              title={title}
              date={isoDate}
              link={link}
              rawContent={content.encodedSnippet}
              content={content.encoded}
            />
          ))}
        </Container>
      );
    }}
  />
);

export default Medium;

import React, { FC } from 'react';

import { IMetaPreview } from 'interfaces/page';
import { IMediaCoverage } from 'interfaces/press';

import { Wrapper } from './NewsLinks.styled';
import NewsItem from '../NewsItem/NewsItem';

interface INewsLinks {
  articles: IMediaCoverage[];
  maxShown?: number;
  defaultImage?: IMetaPreview;
}

const NewsLinks: FC<INewsLinks> = ({
  articles,
  defaultImage,
  maxShown = 6,
}) => (
  <Wrapper gridOnly>
    {articles.map((item, index) => index < maxShown && (
      <NewsItem
        key={item.id}
        defaultImage={defaultImage}
        {...item}
      />
    ))}
  </Wrapper>
);

export default NewsLinks;

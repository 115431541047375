import React, {
  FC, useRef, useEffect, useState,
} from 'react';

import { IMediaCoverage } from 'interfaces/press';
import { MAX_MOB_WIDTH } from 'constants/sizes';

import { Container, TouchSlider, Title } from './Interviews.styled';
import VideoItem from './VideoItem/VideoItem';

interface IInterviews {
  items: IMediaCoverage[];
  className?: string;
}

const Interviews: FC<IInterviews> = ({ items, className }) => {
  const containerRef = useRef<HTMLDivElement>();
  const [itemWidth, setItemWidth] = useState<number>(80);
  const [activeItem, setActiveItem] = useState<number>(0);

  const handleResize = () => {
    if (!window) return;

    if (window.innerWidth < MAX_MOB_WIDTH) {
      setItemWidth(100);
    } else {
      setItemWidth(80);
    }
  };

  const handleChangeSlide = (index: number) => {
    setActiveItem(index);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container gridOnly className={className}>
      <Title>Interviews</Title>
      <TouchSlider
        ref={containerRef}
        items={items.map(({ title }) => title)}
        itemWidth={itemWidth}
        onChange={handleChangeSlide}
      >
        {items.map(({
          link, title, id, date,
        }, index) => (
          <VideoItem
            key={id}
            link={link}
            title={title}
            date={date}
            isActive={activeItem === index}
          />
        ))}
      </TouchSlider>
    </Container>
  );
};

export default Interviews;

import React, { FC } from 'react';

import {
  Wrapper, Title, Date, Image, Link, ImageWrapper,
} from './MediumArticle.styled';

interface IMediumArticle {
  link: string;
  title: string;
  content: string;
  rawContent: string;
  date: string;
  className?: string;
}

const MediumArticle: FC<IMediumArticle> = ({
  link,
  title,
  content = '',
  rawContent = '',
  date,
  className,
}) => {
  const getImageUrl = (): string => {
    const regexp = /(https:\/\/cdn-images\S+\.medium.com\S+(?:png|jpe?g|gif))/igm;
    const url = content.match(regexp)?.[0];

    return url || '';
  };

  const getReadTime = (): number => {
    const wordsCount = rawContent.replace('\n', ' ').split(' ').length;

    return Math.round(wordsCount / 265 + 1.5);
  };

  return (
    <Wrapper className={className} gridOnly>
      <Title>
        <Link href={link} target="_blank" rel="noreferrer">
          {title}
        </Link>
      </Title>
      <Date>{`${date} · ${getReadTime()} min read`}</Date>
      <ImageWrapper href={link} target="_blank">
        <Image src={getImageUrl()} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default MediumArticle;

import React, { FC, forwardRef, Ref } from 'react';

import { IMediaImage } from 'interfaces/media';

import { Wrapper, DownloadButton, Picture } from './Image.styles';

interface IImage extends IMediaImage {
  className?: string;
  ref?: Ref<HTMLDivElement>;
}

const Milestone: FC<IImage> = forwardRef(({
  className = '', ...res
}, ref) => (
  <Wrapper
    ref={ref}
    className={className}
  >
    <Picture {...res} />
    <a href={res.localFile.publicURL} target="_blank" download rel="noreferrer">
      <DownloadButton />
    </a>
  </Wrapper>
));

export default Milestone;

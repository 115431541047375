import styled from '@emotion/styled';

import {
  DownloadButton as UIDownloadButton, GatsbyPicture,
} from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const DownloadButton = styled(UIDownloadButton)`
  pointer-events: auto;
  background: var(--white-opacity);
  backdrop-filter: blur(0.2em);

  :hover {
    background: var(--white-opacity-1);
  }
`;

export const Wrapper = styled.div`
  grid-area: b;
  grid-column: span 4;
  grid-row: span 1;
  position: relative;
  align-self: stretch;
  height: 0;
  top: auto;
  padding-bottom: 66.6%;

  ${DownloadButton} {
    position: absolute;
    right: 1.5em;
    bottom: 1.5em;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 2;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 100%;
    grid-area: none;
    grid-column: auto;
    grid-row: auto;
    position: relative;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
  }
`;

import { IMetaPreview } from 'interfaces/page';
import { IMediaCoverage } from 'interfaces/press';
import React, { FC } from 'react';

import {
  Card, Picture,
} from './NewsItem.styled';

interface INewsItem extends IMediaCoverage {
  defaultImage?: IMetaPreview;
}

const NewsItem: FC<INewsItem> = ({
  id,
  title,
  date,
  link,
  media,
  defaultImage,
  files,
}) => {
  const getFile = () => {
    const openGraph = files?.[0];
    const localFile = openGraph?.localFile?.childImageSharp ? openGraph : media;

    return localFile?.localFile || defaultImage?.localFile;
  };

  return (
    <Card
      key={id}
      title={title}
      date={date}
      link={link}
      withSource
    >
      <Picture
        {...media}
        localFile={getFile()}
        alternativeText={media?.alternativeText || title}
      />
    </Card>
  );
};

export default NewsItem;
